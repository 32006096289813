import ServiceIsBusyError from '@/Errors/ServiceIsBusyError';
import AxiosRequest from '@/Services/AxiosRequest';
import {route, trans} from '@/Utility/Helpers';
import User from '@/Models/User/User';
import {UserRole} from '@/Models/User/UserRole';
import type Unit from '@/Models/Unit/Unit';
import type Course from '@/Models/Course/Course';
import {TenantRole} from '@/Models/Tenant/TenantRole';
import moment from 'moment';
import PagingMetadata from '@/Models/PagingMetadata';
import PagingPage from '@/Models/PagingPage';

export type UserToImport = {
    firstname: string,
    lastname: string,
    email: string,
    tenant_role_name: TenantRole.Name,
    user_role_name: UserRole
};

export default class UserService {

    /**
     * Currently loaded page of instance users.
     */
    public instanceUserPage = new PagingPage<User>();

    public users: User[] = [];
    public isDeleting: boolean = false;
    public isLoading: boolean = false;
    public isSaving: boolean = false;
    private request: AxiosRequest | null = null;

    /**
     * Cancel any ongoing requests.
     */
    async cancelRequests(): Promise<any> {
        return await this.request?.cancel();
    }

    /**
     * Fetch all users for the current user from API.
     */
    async fetchUsers(): Promise<User[]> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isLoading = true;
        this.request = new AxiosRequest();

        return this.request
            .get(route('api.tenants.users.index', {tenant: window.currentUser?.tenant?.uid}))
            .then(({data}: any) => {
                this.users = [];
                data.data.forEach((userData: any): void => {
                    try {
                        this.users.push(new User(userData));
                    } catch (ex) {
                        console.warn('UserService->fetchUsers(): Skipping user with invalid or incompatible data.', userData, ex);
                    }
                });
                return Promise.resolve(this.users);
            })
            .finally((): void => {
                this.isLoading = false;
                this.request = null;
            });
    }

    /**
     * Fetch all users for the instance from API.
     */
    async fetchInstanceUsers(
        page: number = 1,
        filters: string[]|null = null,
        search: string|null = null,
        orderBy: string|null = null,
        descending: boolean = false
    ): Promise<PagingPage<User>> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isLoading = true;
        this.request = new AxiosRequest();

        const params = {
            page: page,
            filter: filters,
            search: search,
            sort: orderBy,
            sort_order: 'asc',
        };

        if (descending) {
            params.sort_order = 'desc';
        }

        return this.request
            .get(route('api.manage.users.index'), { params: params })
            .then(({ data }: any) => {
                const pagingMetadata = new PagingMetadata(data.meta);
                const users = data.data.map(userData => {
                    try {
                        return new User(userData);
                    } catch (ex) {
                        console.warn('UserService->fetchInstanceUsers(): Skipping user with invalid or incompatible data.', userData, ex);
                        return null;
                    }
                }).filter(c => c instanceof User);
                this.instanceUserPage = new PagingPage(users, pagingMetadata);
                return Promise.resolve(this.instanceUserPage);
            })
            .finally((): void => {
                this.isLoading = false;
                this.request = null;
            });
    }

    /**
     * Fetch all users for the instance from API.
     */
    async deleteInstanceUser(userUid: string): Promise<void> {
        if (this.isDeleting || this.request?.isBusy) {
            throw new ServiceIsBusyError();
        }

        this.isDeleting = true;
        this.request = new AxiosRequest();

        return this.request
            .delete(route('api.manage.users.delete', { user: userUid }))
            .then(() => {
                this.instanceUserPage.removeItem(userUid);
            })
            .finally((): void => {
                this.isDeleting = false;
                this.request = null;
            });
    }

    /**
     * Delete user
     */
    async deleteUser(userUid: string): Promise<void> {
        if (this.isDeleting || this.request?.isBusy) {
            throw new ServiceIsBusyError();
        }

        this.isDeleting = true;
        this.request = new AxiosRequest();

        return this.request
            .delete(route('api.users.delete', { user: userUid }))
            .then(() => {
                return Promise.resolve();
            })
            .finally((): void => {
                this.isDeleting = false;
                this.request = null;
            });
    }

    /**
     * Creates a new user through the API.
     */
    async createUserFromFormData(formData: Record<string, any>): Promise<User> {
        if (this.isSaving || this.request?.isBusy) {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        return await this.request.post(
            route('api.users.create'),
            formData
        ).then(({data}: any): Promise<User> => {
            try {
                const user = new User(data);
                //console.info('UserService->createUserFromFormData(): User created.', user, data);
                return Promise.resolve(user);
            } catch (ex) {
                console.error('UserService->createUserFromFormData(): API returned invalid or incompatible user data.', data, ex);
                return Promise.reject(trans('errors.user.create_parse_failed'));
            }
        }).finally((): void => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Update a user through the API.
     */
    async updateUserProfile(user: User, formData: Record<string, any>): Promise<User> {
        if (this.isSaving || this.request?.isBusy) {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        return await this.request.post(
            route('api.users.update', {user: user.uid}),
            formData
        ).then(({data}: any): Promise<User> => {
            try {
                const user = new User(data);
                //console.info('UserService->updateUserProfile(): User updated.', user, data);
                return Promise.resolve(user);
            } catch (ex) {
                console.error('UserService->updateUserProfile(): API returned invalid or incompatible user data.', data, ex);
                return Promise.reject(trans('errors.user.create_parse_failed'));
            }
        }).finally((): void => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Download exported users CSV file
     */
    async downloadExportedUsersCSV(): Promise<void> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isLoading = true;
        this.request = new AxiosRequest();

        await this.request.get(route('api.users.export'))
            .then(async (response): Promise<void> => {

                let csvString = response.data as string;

                // add back BOM bytes if not yet present
                if (!csvString.startsWith('\ufeff')) {
                    csvString = '\ufeff' + csvString;
                }

                const blob = new Blob([csvString], {type: 'text/csv'});
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');

                const tenantName = window.currentUser?.tenant?.name || '';
                const date = moment().format('YYYYMMDD-HHmmss');
                const filename = (`Users-${tenantName}-${date}`)
                    .replace(' ', '-')
                    .replace(/[^a-z\d]/gi, '-') + '.csv';

                link.href = url;
                link.download = filename;
                link.click();

                // Revoke the URL to release resources
                URL.revokeObjectURL(url);

            })
            .finally((): void => {
                this.isLoading = false;
                this.request = null;
            })
    }

    /**
     * Bulk import users
     */
    async importUsers(userList: UserToImport[]): Promise<void> {

        if (this.isSaving || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        try {
            const response = await this.request.post(route('api.users.import'), {users: userList});

            const newUsers = response.data.data.map((userData: any) => {
                try {
                    return new User(userData);
                } catch (ex) {
                    console.warn('UserService->importUsers(): Skipping user with invalid or incompatible data.', userData, ex);
                }
            });

            this.addUsers(...newUsers);

            return newUsers;

        } finally {
            this.isSaving = false;
            this.request = null;
        }
    }

    /**
     * Get a specific user by its UID.
     */
    getUserByUid(uid: string): User | null {
        return this.users.find((user: User): boolean => user.uid === uid) || null;
    }

    /**
     * Get all loaded users with the given tenant role inside the current tenant.
     */
    getUsersByTenantRole(tenantRole: TenantRole.Name): User[] {
        return this.users.filter((user: User): boolean => user.tenant_role?.is(tenantRole) || false) || [];
    }

    /**
     * Get assigned authors for a given unit.
     */
    getAssignedAuthorsForUnit(unit: Unit): User[] {
        return (unit.authors.length >= 1) ? this.users.filter((user: User): boolean => unit.authors.includes(user.uid)) : [];
    }

    /**
     * Get unassigned authors for a given unit.
     */
    getUnassignedAuthorsForUnit(unit: Unit): User[] {
        const limitedAuthors: User[] = this.getUsersByTenantRole(TenantRole.Name.AuthorLimited);
        return (unit.authors.length >= 1) ?
            limitedAuthors.filter((author: User): boolean => !unit.authors.includes(author.uid)) :
            limitedAuthors;
    }

    /**
     * Get enrolled users for a given course.
     */
    getEnrolledUsersForCourse(course: Course): User[] {
        return course.hasEnrolledUsers ? this.users.filter((user: User): boolean => course.isEnrolled(user)) : [];
    }

    /**
     * Get unenrolled users for a given course.
     */
    getUnenrolledUsersForCourse(course: Course): User[] {
        return course.hasEnrolledUsers
            ? this.users.filter((user: User): boolean => !course.isEnrolled(user) && !user.hasAnyRole(UserRole.Lms, UserRole.WebApp))
            : this.users.filter((user: User): boolean => !user.hasAnyRole(UserRole.Lms, UserRole.WebApp));
    }

    private addUsers(...users: User[]) {
        this.users.push(...users);
        this.users.sort((user1, user2) => user1.compareByLastname(user2))
    }
}
