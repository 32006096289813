<template>
    <Modal
        ref="modal"
        event-type="MODAL_UNIT_LINKS_UNLOCK"
        :show-close-button="false"
        :user-closable="false"
    >

        <template #header>
            <h2>{{ trans('units.launch.unit_link.password.title') }}</h2>
        </template>

        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="trans('units.launch.unit_link.password.description')" />

        <TextInput
            ref="passwordInput"
            :model="form"
            :placeholder="trans('labels.password')"
            class="no-wrap"
            icon="icon_copy"
            property="password"
            type="password"
            :validation-errors="errors"
            @change="onPasswordChanged"
            @key-enter="onUnlockUnitLink"
        />

        <template #buttons>
            <ButtonPrimary
                caption="units.launch.unit_link.password.apply"
                @trigger="onUnlockUnitLink"
            />
        </template>
    </Modal>
</template>

<script lang="ts">

import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import type { PropType} from 'vue';
import {inject} from 'vue';
import {defineComponent} from 'vue';
import TextInput from '@/Vue/Common/TextInput.vue';
import {trans} from '@/Utility/Helpers';
import Modal from '@/Vue/Modals/Modal.vue';
import UnitLink from '@/Models/Unit/UnitLink';
import {unitLinkServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import EventType from '@/Utility/EventType';

export default defineComponent({
    components: {
        Modal,
        TextInput,
        ButtonPrimary,
    },
    props: {
        unitLink: {
            type: UnitLink as PropType<UnitLink | null>,
            default: null,
        },
    },

    emits: [
        'success',
    ],

    data() {
        return {
            unitLinkService: inject(unitLinkServiceKey)!,
            form: {
                password: null as string | null,
            },
            errors: [],
        };
    },

    methods: {
        trans,

        onPasswordChanged() {
            this.errors = [];
        },

        onUnlockUnitLink() {

            (this.$refs.passwordInput as typeof TextInput).focus();

            if (!this.unitLink || !this.form.password || this.form.password.trim() === '') {
                return;
            }

            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.unlocking_unit'));
            this.unitLinkService.unlockUnitLink(this.unitLink, this.form.password)
                .then((accessToken: string) => {
                    (this.$refs.modal as typeof Modal).hide();
                    this.$emit('success', accessToken);
                })
                .catch((error) => {
                    if (error.response?.data?.errors) {
                        this.errors = Object.values(error.response?.data?.errors || {});
                    } else {
                        this.$root!.showErrorDialog(error);
                    }
                }).finally(() => {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            });
        },
    },
});
</script>

<style lang="css" scoped>
</style>
