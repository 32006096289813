import ServiceIsBusyError from '@/Errors/ServiceIsBusyError';
import AxiosRequest from '@/Services/AxiosRequest';
import {route, sortArrayByDate, trans} from '@/Utility/Helpers';
import PagingMetadata from '@/Models/PagingMetadata';
import Unit from '@/Models/Unit/Unit';
import UnitPage from '@/Models/Unit/UnitPage';
import UnitRevision from '@/Models/Unit/UnitRevision';
import CourseWithoutRelations from "@/Models/Course/CourseWithoutRelations";

export default class UnitService {

    static get NumberOfUnitsPerPage() {
        return 150;
    }

    /**
     * Constructor
     */
    constructor() {
        /**
         * Currently loaded unit page.
         * @type {UnitPage}
         */
        this.unitPage = new UnitPage([], new PagingMetadata());

        this.isLoading = false;             // Loading state
        this.isSaving = false;              // Saving state
        this.request = null;                // The current request
    }

    /**
     * Cancel any ongoing requests
     *
     * @async
     * @returns {Promise}
     */
    async cancelRequests() {
        // @NOTE: Only working with a single request at the moment!
        if (this.request !== null) {
            return await this.request.cancel();
        }
        return Promise.resolve('Requests canceled');
    }

    /**
     * Creates a new unit through the API.
     *
     * @async
     * @param {FormData} formData
     * @return {Promise<Unit>}
     */
    async createUnitFromFormData(formData) {
        if (this.isSaving === true || this.request?.isBusy) {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        return await this.request.post(
            route('api.units.create'),
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then(({data}) => {
            try {
                const unit = new Unit(data.data);
                // Update the modified unit in the originally fetched list
                this.setUnit(unit);
                // console.info('UnitService->createUnitFromFormData(): Unit created.', unit, data);
                return Promise.resolve(unit);
            } catch (ex) {
                console.error('UnitService->createUnitFromFormData(): API returned invalid or incompatible unit data.', data, ex);
                return Promise.reject(trans('errors.unit.invalid_data'));
            }
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Creates a new unit from the given unit with the given revision uid.
     *
     * @async
     * @param {Unit} unit Unit to duplicate
     * @param {string} revisionUid Uid of the revision to duplicate the unit data from. Scene uids will be changed.
     * @param {string} newTitle Required new title of the duplicated unit.
     * @param {boolean} keepAssignedAuthors If true all assigned authors will be duplicated to the new unit as well.
     * @return {Promise<Unit>}
     */
    async createUnitFromRevision(unit, revisionUid, newTitle, keepAssignedAuthors) {
        if (this.isSaving === true || this.request?.isBusy) {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        return await this.request.post(
            route('api.units.revisions.create_unit', {unit: unit.uid, unitRevision: revisionUid}),
            {
                title: newTitle,
                keepAssignedAuthors: keepAssignedAuthors,
            }
        ).then(({data}) => {
            try {
                const unit = new Unit(data.data);
                // Update the modified unit in the originally fetched list
                this.setUnit(unit);
                return Promise.resolve(unit);
            } catch (ex) {
                console.error('UnitService->createUnitFromRevision(): API returned invalid or incompatible unit data.', data, ex);
                return Promise.reject(trans('errors.unit.invalid_data'));
            }
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Delete a unit through the API
     *
     * @async
     * @param {Unit} unit
     * @returns {Promise<Unit>}
     */
    async deleteUnit(unit) {
        if (this.isSaving === true || this.request?.isBusy) {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        return await this.request.delete(
            route('api.units.delete', {unit: unit.uid})
        ).then(({ data }) => {
            try {
                // Remove the deleted unit from the originally fetched list:
                this.removeUnit(unit);
                // Update list sorting:
                sortArrayByDate(this.unitPage.unitList, 'updated_at', true);
                //console.info('UnitService->deleteUnit(): Unit removed.', unit, data);
                return Promise.resolve(unit);
            }catch(ex) {
                console.error('UnitService->deleteUnit(): API returned invalid or incompatible unit data.', data, ex);
                return Promise.reject(trans('errors.unit.invalid_data'));
            }
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Duplicate a unit through the API
     *
     * @async
     * @param {Unit} unit
     * @param {string} newTitle
     * @param {boolean} keepAssignedAuthors
     * @param {string} targetTenantUid
     * @returns {Promise}
     */
    async duplicateUnit(unit, newTitle, keepAssignedAuthors, targetTenantUid) {
        if (this.isSaving === true || this.request?.isBusy) {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        return await this.request.post(
            route('api.units.duplicate', { unit: unit.uid }),
            {
                title: newTitle,
                keepAssignedAuthors: keepAssignedAuthors,
                target_tenant_uid: targetTenantUid,
            }
        ).then(({ data }) => {
            try {
                const unit = new Unit(data.data);
                // Update the modified unit in the originally fetched list
                this.setUnit(unit);
                //console.info('UnitService->duplicateUnit(): Unit duplicated.', unit, data);
                return Promise.resolve(unit);
            }catch(ex) {
                console.error('UnitService->duplicateUnit(): API returned invalid or incompatible unit data.', data, ex);
                return Promise.reject(trans('errors.unit.invalid_data'));
            }
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Import the unit as template.
     *
     * @async
     * @param {Unit} unit
     * @param {String} newTitle
     * @returns {Promise<Unit>}
     */
    async importUnitAsTemplate(unit, newTitle) {
        if (this.isSaving === true || this.request?.isBusy) {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        return await this.request.post(
            route('api.units.import-template', {unit: unit.uid}),
            {
                title: newTitle,
            }
        ).then(({data}) => {
            try {
                const unit = new Unit(data.data);
                // Update the modified unit in the originally fetched list
                this.setUnit(unit);
                //console.info('UnitService->importUnitAsTemplate(): Unit imported as template.', unit, data);
                return Promise.resolve(unit);
            } catch (ex) {
                console.error('UnitService->importUnitAsTemplate(): API returned invalid or incompatible unit data.', data, ex);
                return Promise.reject(trans('errors.unit.invalid_data'));
            }
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Fetch a specific unit
     *
     * @async
     * @param {Unit} unit
     * @returns {Promise}
     */
    async fetchUnit(unit) {
        if (this.isLoading === true || this.request?.isBusy)
        {
            throw new ServiceIsBusyError();
        }
        this.isLoading = true;
        this.request = new AxiosRequest();
        return await this.request.get(
            route('api.units.details', {'unit' : unit.uid})
        ).then(({ data }) => {
            try {
                const unit = new Unit(data.data);
                // Update the modified unit in the originally fetched list:
                this.setUnit(unit);
                return Promise.resolve(unit);
            }catch(ex) {
                console.error('UnitService->fetchUnit(): API returned invalid or incompatible unit data.', data, ex);
                return Promise.reject(trans('errors.unit.invalid_data'));
            }
        }).finally(() => {
            this.isLoading = false;
            this.request = null;
        });
    }

    /**
     * Fetch details for a specific unit revision (history)
     *
     * @async
     * @param {UnitRevision} revision
     * @returns {Promise}
     */
    async fetchUnitRevision(revision) {
        if (this.isLoading === true || this.request?.isBusy)
        {
            throw new ServiceIsBusyError();
        }
        this.isLoading = true;
        this.request = new AxiosRequest();
        return await this.request.get(
            route('api.units.revisions.read', {'unit' : revision.unit_uid, 'unitRevision': revision.uid})
        ).then(({ data }) => {
            try {
                const revision = new UnitRevision(data.data, null);

                // Update the revision on the related unit:
                const relatedUnit = this.getUnitByUid(revision.unit_uid);
                if (relatedUnit !== null)
                {
                    revision.parent = relatedUnit;
                    const index = relatedUnit.revisions.findIndex(r => r.uid === revision.uid);
                    if (index >= 0)
                    {
                        relatedUnit.revisions.splice(index, 1, revision);
                    }
                    else
                    {
                        relatedUnit.revisions.push(revision);
                    }
                }

                return Promise.resolve(revision);
            }catch(ex) {
                console.error('UnitService->fetchUnitRevision(): API returned invalid or incompatible unit revision data.', data, ex);
                return Promise.reject(trans('errors.unit.invalid_data'));
            }
        }).finally(() => {
            this.isLoading = false;
            this.request = null;
        });
    }

    /**
     * Fetch all courses for the current user from API
     *
     * @async
     * @param {number} page current page to fetch; starting with 1
     * @param {number|null} perPage number of entries per page
     * @param {object|null} filters e.g. {policy: ["standard", "sample", "template"], status: ["draft", "released", "unreleased_changes"], type: ["360", "ar", "vr"]}
     * @param {string|null} search keywords
     * @param {string|null} orderBy e.g. "title", "created_at", "updated_at"
     * @param {boolean} descending
     * @returns {Promise<UnitPage>}
     */
    async fetchUnits(page = 1, perPage = null, filters = null, search = null, orderBy = null, descending = false) {
        if (this.isLoading === true || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isLoading = true;
        this.request = new AxiosRequest();

        const params = {
            page: page,
            per_page: perPage || UnitService.NumberOfUnitsPerPage,
            filter: filters,
            search: search,
            sort: orderBy,
        };

        if (descending) {
            params.sort_order = 'desc';
        }

        return await this.request.get(
            route('api.units.index'),
            {
                params: params
            }
        ).then(({data}) => {
            const pagingMetadata = new PagingMetadata(data.meta);
            const units = data.data.map(unitData => {
                try {
                    return new Unit(unitData);
                } catch (ex) {
                    console.warn('UnitService->fetchUnits(): Skipping unit with invalid or incompatible data.', unitData, ex);
                    return null;
                }
            }).filter(c => c instanceof Unit);
            this.unitPage = new UnitPage(units, pagingMetadata);
            return Promise.resolve(this.unitPage);
        }).finally(() => {
            this.isLoading = false;
            this.request = null;
        });
    }

    /**
     * Fetch the updated_at date for a specific unit
     *
     * @async
     * @param {Unit} unit
     * @returns {Promise}
     */
    async fetchUnitUpdatedAt(unit) {
        if (this.isLoading === true || this.request?.isBusy)
        {
            throw new ServiceIsBusyError();
        }
        this.isLoading = true;
        this.request = new AxiosRequest();
        return await this.request.get(
            route('api.units.details.updated_at', {'unit' : unit.uid})
        ).then(({ data }) => {
            return Promise.resolve(data);
        }).finally(() => {
            this.isLoading = false;
            this.request = null;
        });
    }

    /**
     * Release a unit through the API
     *
     * @async
     * @param {Unit} unit
     * @returns {Promise}
     */
    async releaseUnit(unit) {
        if (this.isSaving === true || this.request?.isBusy) {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        return await this.request.post(
            route('api.units.release', {unit: unit.uid})
        ).then(({ data }) => {
            try {
                const unit = new Unit(data.data);
                // Update the modified unit in the originally fetched list
                this.setUnit(unit);
                //console.info('UnitService->releaseUnit(): Unit released.', unit, data);
                return Promise.resolve(unit);
            }catch(ex) {
                console.error('UnitService->releaseUnit(): API returned invalid or incompatible unit data.', data, ex);
                return Promise.reject(trans('errors.unit.invalid_data'));
            }
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Update a unit through the API
     *
     * @async
     * @param {Unit} unit
     * @param {UnitRevision} revision
     * @returns {Promise}
     */
    async updateUnit(unit, revision = null) {
        if (this.isSaving === true || this.request?.isBusy)
        {
            throw new ServiceIsBusyError();
        }
        this.isSaving = true;

        // Clean up unit data:
        unit.cleanUpData();
        if (revision !== null) {
            revision.cleanUpData();
        }

        // Prepare form data if a preview file should be included:
        const dataToUpdate = {
            policy: unit.policy
        };
        let formData = {unit: dataToUpdate};
        let config = {};
        let method = 'patch';
        if (revision && revision.previewImageForUpload instanceof File)
        {
            // Send a POST request since PATCH doesn't support files:
            formData = new FormData();
            method = 'post';
            formData.append('_method', 'PATCH');
            formData.append('unit', JSON.stringify(dataToUpdate));
            if (revision.unit_data !== null) {
                formData.append('unit_data', JSON.stringify(revision.unit_data));
            }
            formData.append('preview_image', revision.previewImageForUpload);
            config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
        }else if (revision !== null) {
            formData.unit_data = revision.unit_data;
        }

        this.request = new AxiosRequest();
        return await this.request[method](
            route('api.units.update', {unit: unit.uid}),
            formData,
            config
        ).then(({ data }) => {
            try {
                const unit = new Unit(data.data);
                // Update the modified unit in the originally fetched list:
                this.setUnit(unit);
                //console.info('UnitService->updateUnit(): Unit updated.', unit, data);
                return Promise.resolve(unit);
            }catch(ex) {
                console.error('UnitService->updateUnit(): API returned invalid or incompatible unit data.', data, ex);
                return Promise.reject(trans('errors.unit.invalid_data'));
            }
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Adds and/or removes author assignments through the API.
     * All given users must be members of the logged-in user's current tenant.
     * On success the author list of the given unit will be updated.
     *
     * @param {Unit} unit Unit that authors should be assigned to / unassigned from.
     * @param {User[]} usersToAssign List users that should be assigned as authors.
     * Users that are already assigned will not be added again.
     * @param {User[]} usersToUnassign List of users that should be unassigned as authors.
     * Users that are already unassigned will not throw any errors.
     * @returns {Promise<void>}
     */
    async changeAuthorsAssignment(unit, usersToAssign, usersToUnassign) {
        if (this.isSaving === true || this.request?.isBusy) {
            throw new ServiceIsBusyError();
        }

        const usersToAssignUids = usersToAssign.map(user => user.uid);
        const usersToUnassignUids = usersToUnassign.map(user => user.uid);

        this.isSaving = true;
        this.request = new AxiosRequest();

        return await this.request.post(
            route('api.units.authors', {unit: unit.uid}),
            {
                authors_to_assign: usersToAssignUids,
                authors_to_unassign: usersToUnassignUids,
            }
        ).then(({data}) => {
            // Reflect user changes in given unit reference.
            unit.authors = data.authors;

            // Update unit in the global list, so we don't have to call fetchUnits() again:
            const unitInList = this.getUnitByUid(unit.uid);
            if (unitInList !== null) {
                unitInList.authors = unit.authors;
            }
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Fetches a list of courses (in the current tenant) that each contain the given unit.
     *
     * @param {Unit} unit
     * @returns {Promise<CourseWithoutRelations[]>}
     */
    async getCourses(unit) {
        if (this.isSaving === true || this.request?.isBusy) {
            throw new ServiceIsBusyError();
        }

        this.isLoading = true;
        this.request = new AxiosRequest();

        return await this.request
            .get(route('api.units.courses', {unit: unit.uid}))
            .then(({data}) => {
                return data.data.map(courseData => new CourseWithoutRelations(courseData));
            }).finally(() => {
                this.isLoading = false;
                this.request = null;
            });
    }

    /**
     * Get a specific unit by its UID
     *
     * @param {String} uid
     * @returns {Unit|null}
     */
    getUnitByUid(uid) {
        return this.unitPage.unitList.find((unit) => unit.uid === uid) || null;
    }

    /**
     * Set a specific unit in the list
     *
     * @param {Unit} unit
     */
    setUnit(unit) {
        const unitIndex = this.unitPage.unitList.findIndex(u => u.uid === unit.uid);
        // Replace or add the unit to the cached list
        if (unitIndex >= 0) {
            this.unitPage.unitList.splice(unitIndex, 1, unit);
        } else {
            this.unitPage.unitList.push(unit);
        }
        // Update list sorting
        sortArrayByDate(this.unitPage.unitList, 'updated_at', true);
        return this;
    }

    /**
     * Remove a specific unit from the list
     *
     * @param {Unit} unit
     */
    removeUnit(unit) {
        const unitIndex = this.unitPage.unitList.findIndex(t => t.uid === unit.uid);
        // Remove the unit from the cached list
        if (unitIndex >= 0) {
            this.unitPage.unitList.splice(unitIndex, 1);
        }
        // Update list sorting
        sortArrayByDate(this.unitPage.unitList, 'updated_at', true);
        return this;
    }

    /**
     *
     * @returns {Promise<null|*>}
     * @param {string} packageName
     */
    async importUnit(packageName) {

        if (this.isLoading) {
            throw new ServiceIsBusyError();
        }

        this.isLoading = true;

        try {

            const url = route('api.units.imports.import', {package: packageName});
            const response = await window.axios.post(url);
            return response.data.logs;

        } finally {
            this.isLoading = false;
        }
    }
}
