import {parseDate, uuid4} from '@/Utility/Helpers';
import UserNameOnly from '@/Models/User/UserNameOnly';

export interface UnitLinkAttributes {
    uid: string;
    name: string;
    password: string | null,
    password_required: boolean;
    tenant_uid: string;
    unit_uid: string;
    owned_by: string;
    owner: UserNameOnly | null;
    valid_from: string | null,
    expires_at: string | null;
    deleted_at: string | null;
    created_at: string | null;
    updated_at: string | null;
    url: string | null;
}

export default class UnitLink {

    static constructorName: string = 'UnitLink';

    get constructorName(): string {
        return UnitLink.constructorName;
    }

    public uid: string;
    public name: string;
    public password: string | null;
    public password_required: boolean;
    public tenant_uid: string;
    public unit_uid: string;
    public owned_by: string | null;
    public valid_from: Date | null;
    public expires_at: Date | null;
    public deleted_at: Date | null;
    public created_at: Date | null;
    public updated_at: Date | null;
    public url: string | null;

    public readonly owner: UserNameOnly | null;

    constructor(attributes: UnitLinkAttributes) {
        this.uid = attributes.uid || uuid4();
        this.name = attributes.name;
        this.password = attributes.password;
        this.password_required = attributes.password_required;

        this.tenant_uid = attributes.tenant_uid;
        this.unit_uid = attributes.unit_uid;
        this.owned_by = attributes.owned_by || null;

        this.valid_from = parseDate(attributes.valid_from || null);
        this.expires_at = parseDate(attributes.expires_at || null);
        this.deleted_at = parseDate(attributes.deleted_at || null);
        this.created_at = parseDate(attributes.created_at || null);
        this.updated_at = parseDate(attributes.updated_at || null);

        this.url = attributes.url || null;

        this.owner = (attributes.owner) ? new UserNameOnly(attributes.owner) : null;
    }

    get isDeleted(): boolean {
        return (this.deleted_at !== null);
    }

    get isExpired(): boolean {
        return (
            this.expires_at !== null
            && (this.expires_at < new Date())
        );
    }

    get isNotActiveYet(): boolean {
        return (
            this.valid_from !== null
            && (this.valid_from > new Date())
        );
    }

    get isValid(): boolean {
        return !(
            this.isDeleted
            || this.isExpired
            || this.isNotActiveYet
        );
    }
}
