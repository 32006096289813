<template>

    <footer>
        <div class="footer-stage">
            <div class="footer-stage-links">
                <a
                    :href="trans('modals.about.link_target_imprint')"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {{ trans('modals.about.link_text_imprint') }}
                </a>
                <a
                    :href="trans('modals.about.link_target_tos')"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {{ trans('modals.about.link_text_tos') }}
                </a>
            </div>
            <div class="footer-stage-buttons">
                <ButtonSecondary
                    v-if="helpUrl"
                    caption="labels.help_center"
                    icon="icon_help-center"
                    data-ug-id="unit-launch-btn-help-footer"
                    @trigger="onTriggerHelpButton"
                />
            </div>

        </div>
    </footer>

</template>

<script>
import ButtonSecondary from '@/Vue/Common/ButtonSecondary';
import {trans} from '@/Utility/Helpers';

export default {
    name: 'LaunchFooter',

    components: {
        ButtonSecondary
    },

    props: {
        helpUrl: {
            type: String,
            default: 'https://helpcenter.3spin-learning.com/en/docs',
        }
    },

    methods: {
        trans,

        onTriggerHelpButton() {
            if (window.userGuiding) {
                window.userGuiding.expandResourceCenter();
            } else if (this.helpUrl) {
                window.open(this.helpUrl, '_blank', 'noopener,noreferrer');
            }
        },
    },
};
</script>

<style lang="css" scoped>

footer {
    margin: auto 0 0 0;
}

.footer-stage {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;
    gap: 24px;
    background-color: var(--color-anthracite10);

    .footer-stage-links {
        display: flex;
        flex-direction: row;
        gap: 24px;

        a {
            color: var(--font-color-dark);

            &:hover {
                color: var(--color-primary-hover);
            }
        }
    }

    .footer-stage-buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 12px;
    }
}

</style>
