<template>
    <main id="layout-main" class="unit-launch-error">

        <!-- Header -->
        <header id="unit-launch-header">
            <svg class="logo"><use xlink:href="#logo_3spin" /></svg>

            <ButtonCircular
                v-tooltip="'labels.back'"
                class="btn-back bordered"
                icon="icon_back"
                @trigger="onTriggerBackButton"
            />

            <!-- Help Center -->
            <ButtonCircular
                v-if="helpUrl"
                class="btn-help bordered"
                icon="icon_help-center"
                data-ug-id="unit-launch-btn-help"
                :title="trans('labels.help_center')"
                @trigger="onTriggerHelpButton"
            />
        </header>

        <!-- Error message -->
        <section id="unit-launch-content">
            <h1>{{ trans('units.launch.errors.error_headline') }}</h1>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <h2 v-html="message"></h2>
            <p>{{ trans('units.launch.errors.message_instructions') }}</p>
        </section>
    </main>
</template>

<script>

    import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
    import {trans} from '@/Utility/Helpers/trans';

    export default {
        components: {
            ButtonCircular,

        },
        props: {
            helpUrl: {
                type: String,
                default: 'https://helpcenter.3spin-learning.com/en/docs',
            },
            message: {
                type: String,
                default: null,
            },
        },
        methods: {
            trans,

            onTriggerBackButton() {
                window.history.back();
            },

            onTriggerHelpButton() {
                if (window.userGuiding) {
                    window.userGuiding.expandResourceCenter();
                } else {
                    console.error('UserGuiding script not available!');

                    if (this.helpUrl) {
                        window.open(this.helpUrl, '_blank', 'noopener,noreferrer');
                    }
                }
            },
        },
    }
</script>

<style lang="css" scoped>

    #unit-launch-header {
        display: flex;
        flex-direction: row;
        height: 50px;
        padding: 7px 20px;
        gap: 10px;

        .btn-back {
            margin: 0 auto 0 0;
        }

        .logo {
            display: inline-block;
            flex-basis: 100px;
        }
    }

    /* Hide the logo when navigation is visible */
    #layout-sidemenu ~ #layout-main #unit-launch-header .logo {
        display: none;
    }

    #unit-launch-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px 70px 35px;
        margin: 0 auto;
        gap: 10px;
    }
</style>
