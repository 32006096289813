<template>
    <div>
        <!-- Voice -->
        <div class="form-group row">
            <label class="col-md-4 col-form-label text-md-right">{{ trans('assets.forms.tts.labels.language') }}</label>
            <div class="col-md-6">
                <Dropdown
                    :key="'locale_'+key"
                    :class="'no-wrap'"
                    :model="$data"
                    :options="dropdownOptionsForLanguage"
                    :required="shouldShowContentDescription"
                    property="selectedLocale"
                    @select="onSelectLocale"
                    @click.stop
                />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label text-md-right">{{ trans('assets.forms.tts.labels.voice') }}</label>
            <div class="col-md-6">
                <Dropdown
                    :key="'voice_name_'+key"
                    :class="'no-wrap'"
                    :options="dropdownOptionsForVoiceName"
                    :initial-value="voice.name"
                    :required="shouldShowContentDescription"
                    @select="onSelectVoice"
                    @click.stop
                />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label text-md-right">{{ trans('assets.forms.tts.labels.speaking_style') }}</label>
            <div class="col-md-6">
                <Dropdown
                    :key="'speaking_style_'+key"
                    :disabled="dropdownOptionsForSpeakingStyle.length === 0"
                    :class="'no-wrap text-capitalize'"
                    :model="voice"
                    :property="'speaking_style'"
                    :options="dropdownOptionsForSpeakingStyle"
                    :required="shouldShowContentDescription"
                    @select="onSelectSpeakingStyle"
                    @click.stop
                />
            </div>
        </div>

        <!-- Content description -->
        <div class="form-group row">
            <label for="create-asset-content-description"
                   class="col-md-4 col-form-label text-md-right">{{ trans('labels.content_description') }}</label>

            <div class="col-md-6 ">
                <TextInput
                    v-if="shouldShowContentDescription"
                    :key="'content_description'+key"
                    ref="createAssetContentDescription"
                    id="create-asset-content-description"
                    type="textarea"
                    name="content_description"
                    :initial-value="form.content_description"
                    :validation-errors="validationErrors('content_description')"
                    :maxlength="600"
                    :required="shouldShowContentDescription"
                    :placeholder="trans('assets.forms.content_description_placeholder')"
                    @change="handleContentDescriptionChanged"
                />
            </div>
        </div>

        <!-- Sound synthesis -->
        <div class="form-group row">
            <div class="col-md-4"></div>
            <div class="col-md-6 preview-player">
                <audio
                    ref="audio"
                    :class="{ disabled: !form.content_description || ttsService.isSynthesizing }"
                    controls
                    :src="ttsAudioSource"
                    controlslist="nodownload"
                    @contextmenu.prevent
                    @play="synthesizeIfNeeded(true, true)"
                >
                </audio>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import AssetType from "@/Models/Asset/AssetType";
import type {Dictionary} from "lodash";
import Dropdown from "@/Vue/Common/Dropdown.vue";
import DropdownOption from "@/Utility/DropdownOption";
import DropdownOptionGroup from "@/Utility/DropdownOptionGroup";
import {defineComponent} from "vue";
import {shortId, trans} from "@/Utility/Helpers";
import TextInput from "@/Vue/Common/TextInput.vue";
import TextToSpeechService from "@/Services/CognitiveServices/TextToSpeechService";
import type TextToSpeechResult from "@/Services/CognitiveServices/TextToSpeechResult";
import TextToSpeechVoice from "@/Services/CognitiveServices/TextToSpeechVoice";
import TextToSpeechVoiceConfig from "@/Services/CognitiveServices/TextToSpeechVoiceConfig";

export default defineComponent({
    name: "CreateAssetFormTTS",

    components: {
        Dropdown,
        TextInput
    },

    props: {
        errors: {
            type: Object,
            default: null
        },
        form: {
            type: Object,
            required: true
        },
        ttsService: {
            type: TextToSpeechService,
            default: new TextToSpeechService()
        },
    },

    emits: {
        'content-description-changed': (_: string) => true,
    },

    data() {
        return {
            key: shortId(),
            selectedLocale: 'en-US',
            voice: {
                name: null as string | null,
                speaking_style: 'default' as string | null,
            },
            ttsResult: null as TextToSpeechResult | null,
        };
    },

    computed: {
        allowedLocales(): string[] {
            return ['en-GB', 'en-US', 'fr-FR', 'de-CH', 'de-DE', 'it-IT', 'es-ES'];
        },

        isPreviewPlayerDisabled(): boolean {
            return this.voice.name === null ||
                this.voice.speaking_style === null ||
                this.ttsService.isSynthesizing;
        },

        voicesByLocaleFiltered(): Dictionary<TextToSpeechVoice[]> {
            const filteredByLocale = Object.entries(TextToSpeechVoice.allByLocale)
                .filter(([locale, _]) => this.allowedLocales.includes(locale));

            return Object.fromEntries(filteredByLocale);
        },

        speakingStyleForSelectedVoice(): string[] {
            const speakingStylesForVoice = this.speakingStylesForVoice(this.voice.name || '');

            return speakingStylesForVoice || [];
        },

        dropdownOptionsForLanguage(): DropdownOption[] {
            return [
                ...this.allowedLocales.map((value) => {
                    return new DropdownOption({
                        caption: trans('authoring.ai.voice.language.languages.' + value),
                        value: value,
                    })
                })
            ];
        },

        dropdownOptionsForVoiceName(): DropdownOption[] {
            const groups: DropdownOptionGroup[] = [];
            const options = {
                'with_speaking_styles': this.voicesByLocaleFiltered[this.selectedLocale].filter(voice => voice.hasMultipleStyles),
                'without_speaking_styles': this.voicesByLocaleFiltered[this.selectedLocale].filter(voice => !voice.hasMultipleStyles),
            };

            for (const optionsKey in options) {
                groups.push(new DropdownOptionGroup({
                    caption: trans('authoring.ai.voice.speaking_style.option_groups.' + optionsKey),
                    isSeparator: true,
                    collapsible: false,
                    showGroupNameInCaption: false,
                    options: options[optionsKey].map((voice: TextToSpeechVoice) => {
                        const caption = voice.displayName ? voice.displayName : voice.shortName;
                        const styles = voice.hasMultipleStyles ? ' (' + voice.styleList.filter((style) => style !== 'automatic').length + ')' : '';
                        return new DropdownOption({
                            caption: caption + styles,
                            value: voice.shortName,
                        })
                    })
                }));
            }

            return groups;
        },

        dropdownOptionsForSpeakingStyle(): DropdownOption[] {
            const speakingStylesForVoice = this.speakingStyleForSelectedVoice;

            return [
                ...speakingStylesForVoice.map((value) => {
                    return new DropdownOption({
                        caption: trans('authoring.ai.voice.speaking_style.styles.' + value, {}, false, false) || value,
                        value: value,
                    })
                })
            ];
        },

        shouldShowContentDescription(): boolean {
            return this.form.type === AssetType.SoundTts.type;
        },

        isTtsResultUpToDate(): boolean {
            return this.ttsResult !== null &&
                this.ttsResult?.plainTextInput === this.form?.content_description &&
                this.ttsResult?.voiceConfig.voiceName === this.voice?.name &&
                this.ttsResult?.voiceConfig.style === this.voice?.speaking_style;
        },

        ttsVoice(): TextToSpeechVoiceConfig {
            return new TextToSpeechVoiceConfig(
                this.selectedLocale,
                this.voice.name!,
                true,
                0,
                0,
                this.voice.speaking_style
            );
        },

        ttsAudioSource(): string {
            if (this.isTtsResultUpToDate && this.ttsResult) {
                return window.URL.createObjectURL(this.ttsResult.blob);
            } else {
                // silent audio as fallback to enable play button in html audio element
                return 'data:audio/x-wav;base64,UklGRooWAABXQVZFZm10IBAAAAABAAEAIlYAAESsAAACABAAZGF0YWYWAAAAAA';
            }
        },

        audio(): HTMLAudioElement {
            return this.$refs.audio as InstanceType<typeof HTMLAudioElement>;
        },
    },

    beforeMount() {
        this.onSelectLocale(this.selectedLocale);
    },

    methods: {
        trans,

        reset(): void {
            this.selectedLocale = this.allowedLocales.includes('en-US') ? 'en-US' : this.allowedLocales[0];
            this.voice.speaking_style = 'default';
            this.voice.name = null;
            this.onSelectLocale(this.selectedLocale);
        },

        handleContentDescriptionChanged(newValue: string, _: InputEvent): void {
            newValue = newValue?.trim();
            this.$emit('content-description-changed', newValue);
        },

        /**
         * Get the validation errors for a specific field.
         */
        validationErrors(property: string): string[] {
            return this.errors && Object.prototype.hasOwnProperty.call(this.errors, property) ? this.errors[property] : [];
        },

        getDefaultVoice(locale: string = 'en-US'): TextToSpeechVoice | null {
            return TextToSpeechVoice.allByLocale[locale][0] || null;
        },

        onSelectLocale(locale: string): void {
            const defaultVoice = this.getDefaultVoice(locale)!;
            this.onSelectVoice(defaultVoice?.shortName);
            this.key = shortId();
        },

        onSelectVoice(value: string): void {
            this.voice.name = value;

            // If the new voice supports the current speaking style keep it else reset to default
            if (!this.speakingStylesForVoice(this.voice.name)?.includes(this.voice.speaking_style || '')) {
                this.voice.speaking_style = this.speakingStyleForSelectedVoice[0];
            }

            this.ttsResult = null;
            this.key = shortId();
        },

        onSelectSpeakingStyle(value: string): void {
            this.voice.speaking_style = value;
            this.ttsResult = null;
            this.key = shortId();
        },

        speakingStylesForVoice(voiceName: string): string[] | undefined {
            return this.voicesByLocaleFiltered[this.selectedLocale]?.find(voice => voice.shortName == voiceName)?.styleList.filter((style) => style !== 'automatic');
        },

        async synthesize(playAfterSynthesis: boolean = false): Promise<TextToSpeechResult> {
            this.ttsResult = await this.ttsService.synthesize(this.form.content_description, this.ttsVoice);

            if (playAfterSynthesis) {
                // delay, so new source is already set on the element
                setTimeout(() => this.audio.play(), 100);
            }

            return new Promise<TextToSpeechResult>((resolve, reject) => {
                if (this.ttsResult != null) {
                    resolve(this.ttsResult as TextToSpeechResult);
                } else {
                    reject();
                }
            })
        },

        /**
         * @param {boolean} playAfterSynthesis
         * @param {boolean} showErrorOverlay when true, errors during synthesis will be caught and an error dialog shown
         * @return {Promise<void | TextToSpeechResult>}
         */
        async synthesizeIfNeeded(playAfterSynthesis: boolean = false, showErrorOverlay: boolean = true): Promise<void | TextToSpeechResult> {
            if (!this.shouldShowContentDescription) {
                return Promise.resolve();
            }

            if (this.isTtsResultUpToDate) {
                // synthesis is up-to-date
                return new Promise<TextToSpeechResult>((resolve, reject) => {
                    if (this.ttsResult != null) {
                        resolve(this.ttsResult as TextToSpeechResult);
                    } else {
                        reject();
                    }
                })
            }

            try {
                return await this.synthesize(playAfterSynthesis);
            } catch (e) {
                if (showErrorOverlay) {
                    this.$root!.showErrorDialog(e);
                } else {
                    throw e;
                }
            }

            return Promise.resolve();
        },
    }
});


</script>

<style scoped lang="scss">

</style>
