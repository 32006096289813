// Boot the application:
import './bootstrap';
import { createApp } from 'vue/dist/vue.esm-bundler';

// Include classes:
import RequestError from "@/Errors/RequestError";
import EventType from '@/Utility/EventType';
import {route, trans} from '@/Utility/Helpers';
import {registerComponents} from "@/Vue/Bootstrap/components";
import {registerTemplateMethods} from "@/Vue/Bootstrap/templateMethods";
import {registerRepositories} from "@/Vue/Bootstrap/repositories";
import {registerPlugins} from "@/Vue/Bootstrap/plugins";
import {registerServices} from "@/Vue/Bootstrap/services";
import {registerGlobals} from "@/Vue/Bootstrap/globals";

// Load global CSS
import '../scss/app.scss';
import {AxiosError} from "axios";

/**
 * Initialize global events bind system
 */
const initGlobalEvents = (app) => {
    const ge = app.config.globalProperties.$globalEvents;
    ge.$el = window;
    ge.eventHandlers = {};  // Keeping track of the namespaced events
    ge.addEvent = function(event, callback) {
        const eventType = event.replace(/\..*$/, ''); // e.g. 'click.do-something' => 'click'
        if (typeof ge.eventHandlers[eventType] !== 'object') {
            ge.eventHandlers[eventType] = [];
            ge.$el.addEventListener(eventType, ge.dispatchEvent);
        }
        ge.eventHandlers[eventType].push({
            eventType: eventType,
            event: event,
            callback: callback || null
        });
    };
    ge.removeEvent = function (event) {
        const eventType = event.replace(/\..*$/, ''); // e.g. 'click.do-something' => 'click'
        if (typeof ge.eventHandlers[eventType] === 'object' && ge.eventHandlers[eventType].length > 0) {
            for (let i = (ge.eventHandlers[eventType].length - 1); i >= 0; --i) {
                if (ge.eventHandlers[eventType][i].event === event) {
                    ge.eventHandlers[eventType].splice(i, 1);
                }
                // Make sure the event listener is only bound once per type:
                if (ge.eventHandlers[eventType].length === 0) {
                    ge.$el.removeEventListener(eventType, ge.dispatchEvent);
                    delete ge.eventHandlers[eventType];
                }
            }
        }
    };
    ge.dispatchEvent = function(e) {
        if (typeof ge.eventHandlers[e.type] === 'object') {
            for (let i = 0, ilen = ge.eventHandlers[e.type].length; i < ilen; ++i) {
                if (typeof ge.eventHandlers[e.type] === 'object' && typeof ge.eventHandlers[e.type][i] === 'object') {
                    ge.emit(ge.eventHandlers[e.type][i].event, e);
                    if (typeof ge.eventHandlers[e.type][i].callback === 'function') {
                        ge.eventHandlers[e.type][i].callback.call(this, e);
                    }
                }
            }
        }
    };
    ge.findEventAncestorBySelector = function(e, cssSelector, notCssSelector) {
        // Find the first ancestor (parent) matching a given selector:
        let parent = e.target;
        while (parent !== null) {
            if (typeof parent.matches === 'function' && parent.matches(cssSelector)) {
                return (typeof notCssSelector === 'string' && parent.matches(notCssSelector)) ? null : parent;
            }
            parent = parent.parentNode;
        }
        return null;
    };
    ge.isEventTargetDescendantOfSelector = function(e, cssSelector, notCssSelector) {
        // Check if a given event target is a descendant element (or the same) of a given DOM element with a specific CSS selector:
        return (this.findEventAncestorBySelector(e, cssSelector, notCssSelector || null) !== null) ? true : false;
    };
    // Trigger global event before the page is being unloaded so we can catch it in the app:
    window.addEventListener('beforeunload', function(e) {app.config.globalProperties.$globalEvents.emit(EventType.WINDOW_BEFORE_UNLOAD, e);});
    window.addEventListener('unload', function(e) {app.config.globalProperties.$globalEvents.emit(EventType.WINDOW_UNLOAD, e);});
};

// Create the application:
const app = createApp({
    el: '#app',
    methods: {

        /**
         * Trigger a forced user logout
         */
        forceLogout() {
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
            window.location.href = route('logout');
            return this;
        },

        /**
         * Show an error modal dialog
         *
         * @param {any} error
         */
        showErrorDialog(error) {

            // Do not show errors for any canceled requests:
            if (
                error === null
                || error === undefined
                || error === false
                || (error instanceof AxiosError && error.code === 'ECONNABORTED')
                || window.axios.isCancel(error)
            ) {
                return this;
            }

            if (typeof error !== 'string' && !(error instanceof Error)) {
                console.error('App->showErrorDialog(): Error must be of type string or error.', error);
                throw new TypeError('App->showErrorDialog(): Error must be of type string or error.');
            }

            let errorMessages = (error instanceof Error && typeof error.message === 'string') ? [error.message] : [];

            // Validation error can have multiple messages, so show these instead:
            if (error instanceof RequestError && error.isValidationError) {
                errorMessages = Object.values(error.validationErrors).map(e => e.join('\n'));
            }

            if (errorMessages.length === 0) {
                errorMessages = [typeof error === 'string' ? error : JSON.stringify(error)];
            }

            this.$globalEvents.emit(EventType.MODAL_NOTIFICATION_SHOW, trans('labels.sorry'), errorMessages.join('\n'));

            return this;
        },
    }
});

// Register global components
registerComponents(app);
registerTemplateMethods(app);
registerPlugins(app);
registerRepositories(app);
registerServices(app);
registerGlobals(app);
initGlobalEvents(app);

// Set global data for the Vue application:
app.config.globalProperties.$currentView = window.document.getElementById('app').getAttribute('data-view');       // Name of the currently active view
app.mount('#app');

window.app = app;
