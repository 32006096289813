<template>
    <section class="launch-page-headset-instructions">
        <div class="launch-page-credentials">
            <h2>{{ trans('units.launch.credentials.headline') }}</h2>
            <TextOutputField :text="serverUrl" icon="icon_server" />
            <TextOutputField :text="userId" icon="icon_user" />
            <TextOutputField :text="userPassword" icon="icon_lock_outline" />
            <p class="info">
                <Icon name="icon_info" />
                {{ trans('units.launch.credentials.info') }}
            </p>
        </div>

        <div class="launch-page-steps">
            <h2>{{ trans('units.launch.headset_steps.headline') }}</h2>

            <div class="launch-page-step">
                <img alt="" class="launch-page-step-image" src="/images/unit-launch/getstarted-01_2x.png">
                <div class="launch-page-step-content">
                    <h3>{{ trans('units.launch.headset_steps.1.headline') }}</h3>
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <div v-html="trans('units.launch.headset_steps.1.copy')" />
                </div>
            </div>

            <div class="launch-page-step">
                <img alt="" class="launch-page-step-image" src="/images/unit-launch/getstarted-02_2x.png">
                <div class="launch-page-step-content">
                    <h3>{{ trans('units.launch.headset_steps.2.headline') }}</h3>
                    <p>{{ trans('units.launch.headset_steps.2.copy') }}</p>
                </div>
            </div>

            <div class="launch-page-step">
                <img alt="" class="launch-page-step-image" src="/images/unit-launch/getstarted-03_2x.png">
                <div class="launch-page-step-content">
                    <h3>{{ trans('units.launch.headset_steps.3.headline') }}</h3>
                    <p>{{ trans('units.launch.headset_steps.3.copy') }}</p>
                </div>
            </div>

            <div class="launch-page-step">
                <img alt="" class="launch-page-step-image" src="/images/unit-launch/getstarted-04_2x.png">
                <div class="launch-page-step-content">
                    <h3>{{ trans('units.launch.headset_steps.4.headline') }}</h3>
                    <p>{{ trans('units.launch.headset_steps.4.copy') }}</p>
                </div>
            </div>

        </div>
    </section>

    <section class="launch-page-installation">
        <div class="launch-page-installation-stage">
            <div class="launch-page-installation-teaser">
                <p>{{ trans('units.launch.app_installation.headline') }}</p>
                <h3>{{ trans('units.launch.app_installation.copy') }}</h3>
                <ButtonPrimary
                    caption="units.launch.app_installation.button"
                    @trigger="onInstallationClick"
                />
            </div>
            <img alt="" class="" src="/images/unit-launch/install-3spin-learning_2x.jpg">
        </div>
    </section>
</template>

<script lang="ts">

import Icon from '@/Vue/Common/Icon.vue';
import TextOutputField from '@/Vue/Common/TextOutputField.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import {trans} from '@/Utility/Helpers';
import {defineComponent} from 'vue';
import EventType from '@/Utility/EventType';

export default defineComponent({
    components: {
        Icon,
        TextOutputField,
        ButtonPrimary
    },

    props: {
        serverUrl: {
            type: String,
            required: true,
        },
        userEmail: {
            type: String,
            required: true,
        },
        userPassword: {
            type: String,
            required: true,
        },
    },

    computed: {

        userId(): string {
            // #PRDA-18970: Show full email for now because the app does not append the domain part anymore
            return this.userEmail;
        },
    },

    methods: {
        trans,

        onInstallationClick() {
            this.$globalEvents.emit(EventType.MODAL_INSTALL_APP_SHOW);
        },
    }

});

</script>

<style lang="css" scoped>

.launch-page-headset-instructions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 24px 48px;
    padding: 20px;
    margin-top: 60px;
}

.text-output-field {
    max-width: 400px;
    font-family: var(--font-family-mono-bold);
    font-size: var(--font-size-default);
}

.launch-page-credentials {
    display: flex;
    flex-direction: column;

    > h2 {
        margin-bottom: 24px;
    }

    h4 {
        margin-bottom: 16px;
    }

    .info {
        display: flex;
        align-items: center;
        gap: 4px;
        font-family: var(--font-family-condensed-demibold);
        color: var(--color-anthracite40);
        margin-top: 16px;

        .icon {
            width: 24px;
            height: 24px;
        }
    }
}

.launch-page-steps {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    gap: 24px 32px;

    > h2 {
        margin-bottom: 0;
    }

    h4 {
        margin-bottom: 0;
    }

    :deep(ul) {
        list-style-position: inside;
    }
}

.launch-page-step {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: flex-end;
    gap: 24px;

    .launch-page-step-image {
        flex: 1 1 250px;
        width: 100%;
        max-width: 400px;
        border-radius: var(--card-border-radius);
        box-shadow: var(--card-box-shadow);
    }

    .launch-page-step-content {
        flex: 1 250px;
        max-width: 400px;

        & > ul {
            padding-left: 20px;
            margin-bottom: 1rem;
        }

        & > *:last-child {
            margin-bottom: 0;
        }
    }
}

.launch-page-installation {
    padding: 20px;
    background-color: var(--background-color-light);

    .launch-page-installation-stage {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        max-width: 1368px;
        margin: 0 auto;
        padding: 32px 24px;
        gap: 24px;

        & > * {
            max-width: 500px;
            flex: 1 250px;
        }

        .launch-page-installation-teaser {
            .btn {
                margin-top: 20px;
            }
        }

        img {
            border-radius: var(--card-border-radius);
            flex-shrink: 1;
            width: 100%;
        }
    }
}

@media screen and (max-width: 992px) {
    .launch-page-headset-instructions {
        flex-direction: column;
    }
}

</style>
