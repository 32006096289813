<template>
    <div class="webapp-wrapper">
        <div
            ref="preview"
            :class="{ 'hidden': !(unitRevision.preview && !showCanvas), 'show-start-webapp-button': !startUnitOnLoad }"
            class="unit-preview"
            @click="onStartApp"
        >
            <img
                :src="unitRevision.preview"
                alt=""
                class="unit-preview-image"
            >
            <div v-if="loading" class="loading">
                <LoadingIndicator />
            </div>
            <Icon v-else class="start-webapp-button" name="icon_play" />
        </div>

        <canvas id="unity-canvas" ref="unityCanvas" v-focusable />

        <template v-if="shouldShowControls">
            <QuickGuideEdit v-if="isAuthoring" />
            <QuickGuideView v-else />
        </template>
    </div>
</template>

<script lang="ts">
import {defineComponent, inject, type PropType} from 'vue';
import {Feature} from '@/Models/Features/Feature';
import {featureRepositoryKey} from '@/Vue/Bootstrap/InjectionKeys';
import type {UnityInstance} from '@/Models/Unity/UnityInstance';
import * as MsCognitiveSpeechSdk from 'microsoft-cognitiveservices-speech-sdk';
import EventType from '@/Utility/EventType';
import {trans} from '@/Utility/Helpers/trans';
import LoadingIndicator from '@/Vue/Common/LoadingIndicator.vue';
import Icon from '@/Vue/Common/Icon.vue';
import QuickGuideEdit from '@/Vue/Units/Launch/QuickGuideEdit.vue';
import QuickGuideView from '@/Vue/Units/Launch/QuickGuideView.vue';
import Course from '@/Models/Course/Course';
import UnitRevision from '@/Models/Unit/UnitRevision';
import Tenant from '@/Models/Tenant/Tenant';

declare global {
    // noinspection JSUnusedGlobalSymbols
    /**
     * Make properties of unity loader on window scope known to typescript.
     */
    interface Window {
        getUnitLaunchData: (() => string) | undefined;

        navigateBack: (() => void) | undefined;

        MsCognitiveSpeechSdk: typeof MsCognitiveSpeechSdk | undefined;

        createUnityInstance: ((
            canvas: HTMLCanvasElement,
            config: any,
            onLoadProgress: (number: number) => void
        ) => Promise<UnityInstance>) | undefined;
    }
}

export default defineComponent({
    components: {
        Icon,
        LoadingIndicator,
        QuickGuideEdit,
        QuickGuideView,
    },
    props: {
        tenant: {
            type: Tenant as PropType<Tenant | null>,
            default: null,
        },
        course: {
            type: Course as PropType<Course | null>,
            default: null,
        },
        unitRevision: {
            type: UnitRevision,
            required: true,
        },
        accessToken: {
            type: String,
            default: null,
        },
        isAuthoring: {
            type: Boolean,
            default: false,
        },
        isPreview: {
            type: Boolean,
            default: false,
        },
        buildFilesBaseName: {
            type: String,
            default: '3spinLearning'
        },
        startUnitOnLoad: {
            type: Boolean,
            default: true,
        },
        webappHash: {
            type: String,
            default: null,
        },
    },

    emits: {
        onWebappStarted: () => true,
        onWebappStopped: () => true,
    },

    data() {
        return {
            featureRepository: inject(featureRepositoryKey),
            unityInstance: null as UnityInstance | null,
            showCanvas: false,
            showControls: false,
            started: false,
            loading: false,
            unitHasFinished: false,
        };
    },

    computed: {

        cacheParam() {
            return this.webappHash !== null ? '?cache=' + this.webappHash : '?cache=' + this.releaseVersion;
        },

        playerVersion() {
            if (this.featureRepository?.active(Feature.FeatureWebappPlayerDev)) {
                return 'dev';
            }

            return this.releaseVersion;
        },

        buildUrl() {
            return `/storage/players/${this.playerVersion}/3spinLearning`;
        },

        releaseVersion() {
            return window.appData.APP_RELEASE;
        },

        streamingAssetsUrl() {
            return `/storage/players/${this.playerVersion}/StreamingAssets`;
        },

        unityCanvasElement() {
            return this.$refs.unityCanvas as HTMLCanvasElement;
        },

        shouldShowControls() {
            return this.showControls && this.showCanvas && !this.loading;
        },
    },

    mounted() {
        window.getUnitLaunchData = () => JSON.stringify({
            course_uid: this.course?.uid || null,
            unit_uid: this.unitRevision.unit_uid,
            unit_revision_uid: this.unitRevision.uid,
            tenant_uid: this.tenant?.uid || window.currentUser?.tenant?.uid || null,
            access_token: this.accessToken,
            is_preview: this.isPreview,
            is_authoring: this.isAuthoring,
        });

        window.navigateBack = () => {
            // Make sure to quit the webapp or else starting it multiple times causes artifacts
            this.unityInstance?.Quit().then(() => {
                this.unitHasFinished = true;
                this.started = false;
                this.showCanvas = false;
                this.$emit('onWebappStopped');
            });
        };

        window.MsCognitiveSpeechSdk = MsCognitiveSpeechSdk;

        document.addEventListener('fullscreenchange', this.onChangeFullscreenMode);
        window.addEventListener('beforeunload', this.onBeforeUnload);

        if (this.startUnitOnLoad) {
            this.startApp();
        }
    },

    beforeUnmount() {
        document.removeEventListener('fullscreenchange', this.onChangeFullscreenMode);
        window.removeEventListener('beforeunload', this.onBeforeUnload);
    },

    methods: {

        startApp() {

            if (this.started || !this.accessToken) {
                return;
            }

            this.started = true;
            this.loading = true;
            this.unitHasFinished = false;

            // attach loader script
            const script = document.createElement('script');
            script.src = this.getBuildFileUrl('loader.js');
            script.onload = this.onLoaderScriptLoaded;
            script.onerror = this.onLoaderScriptError;
            document.body.appendChild(script);
        },

        onLoaderScriptError(e: Event) {
            console.error(e);
            this.loading = false;
            this.onLoadError('Unable to load script<br><br><b>' + e.target?.src + '</b>');
        },

        onLoaderScriptLoaded() {
            this.loading = true;

            const config = {
                dataUrl: this.getBuildFileUrl('data.gz'),
                frameworkUrl: this.getBuildFileUrl('framework.js.gz'),
                codeUrl: this.getBuildFileUrl('wasm.gz'),
                streamingAssetsUrl: this.streamingAssetsUrl,
                showBanner: this.onUnityError,
                matchWebGLToCanvasSize: true,
                devicePixelRatio: window.devicePixelRatio,
            };

            if (!window.createUnityInstance) {
                throw new Error('createUnityInstance is not defined');
            }

            window.createUnityInstance(this.unityCanvasElement, config, this.onLoadProgress)
                .then(this.onLoadFinished)
                .catch(this.onLoadError)
                .finally(() => {
                    this.showCanvas = true;
                    this.loading = false;
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                });
        },

        onLoadProgress(progress: number) {
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'), progress);
        },

        onLoadFinished(unityInstance: UnityInstance) {
            this.unityInstance = unityInstance;
            this.loading = false;
            this.$emit('onWebappStarted');
        },

        onLoadError(message: string) {
            this.$root!.showErrorDialog(message);
        },

        onUnityError(message: string, type: 'error' | 'warning') {
            switch (type) {
                case 'error':
                    this.$root!.showErrorDialog(message);
                    break;
                case 'warning':
                    console.warn('onUnityError', message, type);
                    break;
            }
        },

        onStartApp() {
            if (!this.startUnitOnLoad && !this.loading) {
                this.startApp();
            }
        },

        toggleControls() {
            this.showControls = !this.showControls;
        },

        onChangeFullscreenMode() {
            if (document.fullscreenElement !== null) {
                this.unityCanvasElement.classList.add('fullscreen');
            } else {
                this.unityCanvasElement.classList.remove('fullscreen');
            }
        },

        onBeforeUnload(e: BeforeUnloadEvent) {
            if (!this.unloadWebapp()) {
                return;
            }

            e.preventDefault();

            // remove once chrome >= 119 is required
            // noinspection JSDeprecatedSymbols
            e.returnValue = true;
        },

        unloadWebapp() {
            if (!this.unityInstance || this.unitHasFinished) {
                return false;
            }

            // This will call "window.navigateBack" when it finishes so any clean will happen there
            this.unityInstance.SendMessage('WebAppJavaScriptEventHandler', 'OnBeforeUnload');

            return true;
        },

        /**
         * @return path of the file with the given extension inside the build directory
         */
        getBuildFileUrl(extension: string): string {
            return `${this.buildUrl}/${this.buildFilesBaseName}.${extension}` + this.cacheParam;
        },
    },
});
</script>

<style lang="css" scoped>
.unit-preview {
    border-radius: var(--card-border-radius-small);
    background-color: white;
    position: relative;
    overflow: hidden;

    &:not(.hidden) + canvas {
        display: none;
    }

    &.hidden {
        display: none;
    }

    .loading {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0,0,0,0.5);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .unit-preview-image {
        width: 100%;
        height: 100%;
    }

    .start-webapp-button {
        position: absolute;
        height: 140px;
        width: 140px;
        top: calc(50% - 70px);
        left: calc(50% - 70px);
        color: white;
        display: none;
        transition: color .1s;
    }

    &.show-start-webapp-button {
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.4);
            transition: background-color .1s;
        }

        &:hover {
            &:before {
                background-color: rgba(0, 0, 0, 0.3);
            }

            .start-webapp-button {
                color: var(--color-primary-hover);
            }
        }

        .start-webapp-button {
            display: block;
        }
    }
}

canvas {
    background-color: black;
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: auto;
    }
}

.unit-preview {
    height: 100%;
    width: 100%
}

.webapp-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: var(--card-border-radius-small);
    border: 2px solid transparent;
    overflow: hidden;
}

.webapp-wrapper:focus-within {
    border-color: var(--color-primary-active);
}

:deep(.controls) {
    position: absolute;
    top: 24px;
    right: 24px;
    max-height: calc(100% - 48px);
    user-select: none;
}

</style>
