<template>
    <main id="layout-main" class="unit-launch" :class="cssLayoutClasses">

        <!-- Header -->
        <header id="unit-launch-header">
            <svg class="logo"><use xlink:href="#logo_3spin" /></svg>

            <!-- Back Button -->
            <ButtonCircular
                v-if="showBackButton"
                v-tooltip="'labels.back'"
                class="btn-back bordered"
                icon="icon_back"
                data-ug-id="unit-launch-btn-back"
                @trigger="onTriggerBackButton"
            />

            <!-- Title -->
            <h2 v-if="!isViewPreview">
                {{ unitRevision.title }}
            </h2>

            <!-- XR Device Guide -->
            <ButtonSecondary
                v-if="canUseHeadset && !showHeadsetInstructions"
                :caption="trans('units.launch.use_headset')"
                class="hide-on-mobile"
                icon="icon_passthrough-off"
                data-ug-id="unit-launch-btn-xr-guide"
                @trigger="onTriggerOpenInHeadset"
            />
            <ButtonCircular
                v-if="canUseHeadset && !showHeadsetInstructions"
                v-tooltip="trans('units.launch.use_headset')"
                class="bordered"
                icon="icon_passthrough-off"
                @trigger="onTriggerOpenInHeadset"
            />

            <!-- Quick Guide -->
            <ButtonSecondary
                v-if="isViewMaximized"
                caption="labels.quick_guide"
                data-ug-id="unit-launch-btn-quick-guide"
                @trigger="webapp.toggleControls()"
            />

            <!-- Help Center -->
            <ButtonCircular
                v-if="helpUrl"
                class="btn-help bordered"
                icon="icon_help-center"
                data-ug-id="unit-launch-btn-help"
                :title="trans('labels.help_center')"
                @trigger="onTriggerHelpButton"
            />

        </header>

        <!-- Content -->
        <section id="unit-launch-content">

            <!-- Unit Info -->
            <div v-if="isViewPreview" class="unit-info">
                <h1>{{ unitRevision.title }}</h1>

                <!-- Link Error Info -->
                <div v-if="unitLinkError" class="unit-link-error">
                    <h2>{{ unitLinkError.title }}</h2>
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p v-html="unitLinkError.description" />
                </div>

                <!-- eslint-disable-next-line vue/no-v-html -->
                <p v-else v-html="nl2br(unitRevision.description || '')" />
            </div>

            <!-- Webapp Player -->
            <Webapp
                v-if="canUseWebapp"
                ref="webapp"
                data-ug-id="unit-launch-webapp"
                :access-token="unitLinkAccessToken || accessToken"
                :is-authoring="isAuthoring"
                :is-preview="isPreview"
                :start-unit-on-load="shouldAutoStartOnLoad"
                :tenant="tenant"
                :course="course"
                :unit-revision="unitRevision"
                :webapp-hash="webappHash"
                @on-webapp-started="onWebappStarted"
                @on-webapp-stopped="onWebappStopped"
            />

            <!-- Preview Image (when link requires password or unit is only for desktop or XR device) -->
            <div v-else class="unit-preview">
                <img
                    class="unit-preview-image"
                    :alt="unitRevision.title"
                    :src="unitRevision.preview_thumbnail"
                >
            </div>

        </section>

        <!-- XR Headset Instructions -->
        <HeadsetInstructions
            v-if="isViewPreview && canUseHeadset && showHeadsetInstructions"
            :server-url="serverUrl"
            :user-password="userPassword"
            :user-email="userEmail"
        />

        <LaunchFooter
            v-if="isViewPreview && unitLink"
            :help-url="helpUrl"
        />

        <ModalUnlockUnitLink
            v-if="unitLink && unitLink.password_required"
            :unit-link="unitLink"
            @success="onUnlockUnitLink"
        />

        <ModalProgress />
        <ModalNotification />
    </main>
</template>

<script lang="ts">

import {nl2br, trans} from '@/Utility/Helpers';
import type { PropType} from 'vue';
import {defineComponent} from 'vue';
import Course from '@/Models/Course/Course';
import Tenant from '@/Models/Tenant/Tenant';
import type {UnitLinkAttributes} from '@/Models/Unit/UnitLink';
import UnitLink from '@/Models/Unit/UnitLink';
import UnitRevision from '@/Models/Unit/UnitRevision';
import Unit from '@/Models/Unit/Unit';
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import Webapp from '@/Vue/Tools/Webapp/Webapp.vue';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import LaunchFooter from '@/Vue/Units/Launch/LaunchFooter.vue';
import HeadsetInstructions from '@/Vue/Units/Launch/HeadsetInstructions.vue';
import EventType from '@/Utility/EventType';
import moment from 'moment';
import ModalUnlockUnitLink from '@/Vue/Units/Launch/ModalUnlockUnitLink.vue';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';

enum ViewMode {
    Preview = 'preview',
    Maximized = 'maximized',
}

interface UnitLinkError {
    title: string,
    description: string,
}

export default defineComponent({

    components: {
        ModalProgress,
        ModalUnlockUnitLink,
        HeadsetInstructions,
        LaunchFooter,
        ButtonCircular,
        ButtonSecondary,
        ModalNotification,
        Webapp,
    },

    props: {

        courseData: {
            type: Object as PropType<Record<string, any> | null>,
            default: null,
        },
        tenantData: {
            type: Object as PropType<Record<string, any> | null>,
            default: null,
        },
        unitLinkData: {
            type: Object as PropType<UnitLinkAttributes | null>,
            default: null,
        },
        unitRevisionData: {
            type: Object as PropType<Record<string, any> | null>,
            default: null,
        },

        // Whether the feature flag is enabled on the current tenant
        isWebappEnabled: {
            type: Boolean,
            default: true,
        },

        // Login information
        serverUrl: {
            type: String,
            default: null,
        },
        userEmail: {
            type: String,
            default: null,
        },
        userPassword: {
            type: String,
            default: null,
        },

        helpUrl: {
            type: String,
            default: 'https://helpcenter.3spin-learning.com/en/docs',
        },

        isAuthoring: {
            type: Boolean,
            default: false,
        },
        isPreview: {
            type: Boolean,
            default: false,
        },

        accessToken: {
            type: String,
            default: null,
        },
        showBackButton: {
            type: Boolean,
            default: true,
        },
        webappHash: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            showHeadsetInstructions: false,
            unitLinkAccessToken: null as string | null,
            viewMode: (
                this.isAuthoring || this.isPreview || this.courseData
                    ? ViewMode.Maximized
                    : ViewMode.Preview
            ) as ViewMode,
        };
    },

    computed: {

        course(): Course | null {
            return this.courseData ? new Course(this.courseData) : null;
        },

        tenant(): Tenant | null {
            return this.tenantData ? new Tenant(this.tenantData) : window.currentUser!.tenant;
        },

        unit(): Unit {
            return new Unit(this.unitRevisionData?.unit ?? {});
        },

        unitLink(): UnitLink | null {
            return this.unitLinkData ? new UnitLink(this.unitLinkData) : null;
        },

        unitLinkError(): UnitLinkError | null {

            if (this.unitLink?.isExpired || this.unitLink?.isDeleted) {
                return {
                    title: trans('units.launch.unit_link.expired.title'),
                    description: trans('units.launch.unit_link.expired.description'),
                };

            } else if (this.unitLink?.isNotActiveYet) {

                const showTime = moment(this.unitLink!.valid_from).format('HH:mm') !== '00:00';

                return {
                    title: trans('units.launch.unit_link.available_from.title'),
                    description: trans('units.launch.unit_link.available_from.description', {
                        date: moment(this.unitLink!.valid_from)
                            .format(trans('units.launch.unit_link.available_from.date_format')),
                        time: showTime ? moment(this.unitLink!.valid_from).format('HH:mm') : '',
                    }),
                };
            } else if (this.unitLink?.password_required && !this.unitLinkAccessToken) {
                return {
                    title: trans('units.launch.unit_link.password.title'),
                    description: trans('units.launch.unit_link.password.description'),
                };
            }

            return null;
        },

        unitRevision(): UnitRevision {
            return new UnitRevision(this.unitRevisionData, this.unit);
        },

        webapp(): typeof Webapp {
            return this.$refs.webapp as typeof Webapp;
        },

        canUseHeadset() {
            return this.serverUrl && this.userEmail && this.userPassword;
        },

        canUseWebapp() {
            return (
                this.unitLink ? (
                    this.unitLink!.isValid
                    && (
                        !this.unitLink.password_required
                        || this.unitLinkAccessToken
                    )
                ) : (
                    this.isWebappEnabled
                    && this.accessToken
                )
            );
        },

        cssLayoutClasses() {
            return {
                'is-authoring': this.isAuthoring,
                'is-preview': this.isPreview,
                'layout-maximized': this.viewMode === ViewMode.Maximized,
                'layout-preview': this.viewMode === ViewMode.Preview,
            };
        },

        shouldAutoStartOnLoad() {
            return this.isAuthoring || this.isPreview || Boolean(this.course);
        },

        isViewMaximized() {
            return this.viewMode === ViewMode.Maximized;
        },

        isViewPreview() {
            return this.viewMode === ViewMode.Preview;
        },
    },

    mounted() {

        // Show headset instructions initially when webapp is disabled
        if (!this.isWebappEnabled && this.canUseHeadset) {
            this.showHeadsetInstructions = true;
        }

        if (this.unitLink?.password_required) {
            this.$globalEvents.emit(EventType.MODAL_UNIT_LINKS_UNLOCK_SHOW);
        } else if (this.unitLinkError) {
            this.$globalEvents.emit(EventType.MODAL_NOTIFICATION_SHOW, this.unitLinkError.title, this.unitLinkError.description);
        }
    },

    methods: {
        nl2br,
        trans,

        onTriggerOpenInHeadset() {
            if (this.viewMode === ViewMode.Maximized) {
                this.webapp.unloadWebapp();
                return;
            }

            this.showHeadsetInstructions = true;
            this.viewMode = ViewMode.Preview;
        },

        onTriggerBackButton() {
            // Show preview again (unless unit was auto-started)
            if (this.isViewMaximized && !this.shouldAutoStartOnLoad) {
                this.webapp.unloadWebapp();
                this.viewMode = ViewMode.Preview;
            } else {
                window.history.back();
            }
        },

        onTriggerHelpButton() {
            if (window.userGuiding) {
                window.userGuiding.expandResourceCenter();
            } else {
                console.error('UserGuiding script not available!');

                if (this.helpUrl) {
                    window.open(this.helpUrl, '_blank', 'noopener,noreferrer');
                }
            }
        },

        onWebappStarted() {
            this.viewMode = ViewMode.Maximized;
            this.showHeadsetInstructions = false;
        },

        onWebappStopped() {
            if (!this.shouldAutoStartOnLoad) {
                this.viewMode = ViewMode.Preview;
            }

            if (this.shouldAutoStartOnLoad && this.isViewMaximized) {
                return;
            }

            this.showHeadsetInstructions = true;
            this.viewMode = ViewMode.Preview;
        },

        onUnlockUnitLink(accessToken: string) {

            this.unitLinkAccessToken = accessToken;

            window.getUnitLaunchData = () => JSON.stringify({
                course_uid: this.course?.uid || null,
                unit_uid: this.unitRevision.unit_uid,
                unit_revision_uid: this.unitRevision.uid,
                tenant_uid: window.currentUser?.tenant?.uid,
                access_token: this.unitLinkAccessToken ?? this.accessToken,
                is_preview: this.isPreview,
                is_authoring: this.isAuthoring,
            });

            this.$nextTick(() => this.webapp.startApp());
        },
    },
});
</script>

<style lang="css" scoped>

    #layout-main.unit-launch {
        overflow: auto;
    }

    #unit-launch-header {
        display: flex;
        flex-direction: row;
        justify-content: end;
        height: 50px;
        padding: 8px 8px 8px 20px;
        gap: 10px;

        .btn-circular {
            flex-basis: 34px;
            min-width: 34px;
            width: 34px;
            height: 34px;
        }

        .btn:not(.btn-circular) {
            min-width: max-content;
        }

        .btn-back {
            justify-self: start;
            margin: 0 auto 0 0;
        }

        .logo {
            display: inline-block;
            flex-basis: 100px;
            justify-self: start;
        }

        .logo,
        .btn-back {
            + .btn:not(.btn-back) {
                margin-left: auto;
            }
        }

        h2 {
            flex-basis: 100%;
            align-self: center;
            justify-self: start;
            margin: 0 auto 0 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    /* Hide the logo when navigation is visible */
    #layout-sidemenu ~ #layout-main #unit-launch-header .logo {
        display: none;
    }

    #unit-launch-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 10px 70px 35px;
        gap: 50px;

        .unit-info {
            flex-basis: 50vw;
            max-width: 650px;
        }

        .unit-link-error {
            margin-top: 32px;
        }

        .unit-preview {
            border-radius: var(--card-border-radius-small);
            background-color: white;
            overflow: hidden;
            max-width: 320px;

            img {
                width: 100%;
                height: auto;
                border-radius: var(--card-border-radius-small);
            }
        }

        .webapp-wrapper {
            flex-basis: 300px;
            min-height: 168px;
            max-height: 170px; /* for preview layout ! */
        }
    }

    #layout-main.unit-launch.layout-maximized {

        #unit-launch-content {
            flex-basis: calc(100vh - 50px);
            flex-direction: column;
            padding: 0 8px 8px;

            .webapp-wrapper {
                flex-basis: 100%;
                width: 100%;
                height: 100%;
                max-height: calc(100vh - 58px);
            }
        }
    }


    @media only screen and (max-width: 768px) {
        header {
            .hide-on-mobile {
                display: none;
            }
        }
    }

    @media only screen and (min-width: 769px) {
        header {
            .btn-circular:not(.btn-back, .btn-help) {
                display: none;
            }
        }
    }

</style>
